<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <h6>Seller</h6>
            <div class="col-md-4">
              <label>Select State </label>
              <multiselect
                  v-model="state"
                  :disabled="isEdit"
                  :options="stateArr"
                  :show-labels="false"
                  label="state"
                  track-by="stateID"
              ></multiselect>
            </div>

            <div class="col-md-4">
              <label>Mode/Term Of Payment</label>
              <input
                  v-model="paymentMode"
                  class="form-control"
                  type="text"
              />
            </div>
</div>
          <div class="row mt-2" v-if="state">
            <h5>CLOUDKITCH PRIVATE LIMITED</h5>
            <address>
              {{state.cloudKitchBillingAddress}}<br>
              <b>GSTIN/UIN</b> : {{state.cloudKitchGstNo}}<br>
              <b>PAN No.</b> : {{state.panNo}}<br>
              <b>State Name</b> : {{state.state}}, Code : {{state.shortCode}}<br>
              <b>CIN</b> : {{state.cinNo}}<br>
              <b>FSS LIC NO</b> :{{fssLicNo}}<br>
              <b>Contact</b> - {{state.ckPhoneNo}}  <b>Email ID</b> - {{state.ckEmailID}}
            </address>
          </div>
          <div class="row mt-3">
          <h6>Buyer</h6>
            <div
                class="col-md-2"

            >
              <label class="form-label"
              >Select Country
              </label>
              <multiselect
                  v-model="country"
                  :options="countryArr"
                  :show-labels="false"
                  label="country"
                  track-by="country"
                  @input="getAllCities()"
              ></multiselect>
            </div>
            <div
                class="col-md-2"

            >
              <label >Select City </label>
              <multiselect
                  v-model="city"
                  :options="cityArr"
                  :show-labels="false"
                  label="city"
                  track-by="city"
                  @input="getCorporateBranches(),getAllRestaurantBranches()"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label class="form-label">Select Corporate Branch </label>
              <multiselect
                  v-model="corpBranchID"
                  :options="corpBranches"
                  :show-labels="false"
                  label="corpBranchName"
                  track-by="corpBranchID"
              ></multiselect>
              <!-- @input="onchangeBranch();" -->
            </div>
            <div class="col-md-4">
              <label class="form-label" for="form row-endTime-input"
              >Select Restaurant Branch*</label
              >
              <multiselect
                  v-model="restBranchID"
                  :class="{
                  'is-invalid': submitted && $v.restBranchID.$error
                }"
                  :options="restBranchArr"
                  label="restaurantName"
                  track-by="restBranchID"
              ></multiselect>
              <div
                  v-if="submitted && $v.restBranchID.$error"
                  class="invalid-feedback"
              >
                <span v-if="!$v.restBranchID.required"
                >This value is required.</span
                >
              </div>
            </div>
            </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <label>Buyer's Order No</label>
              <input
                  v-model="orderNo"
                  class="form-control"
                  type="text"
              />
            </div>
            <div class="col-md-6 offset-md-2">
              <div class="row">
                <div class="col-md-12">
                  <label>Destination</label>
                  <input
                      v-model="destination"
                      class="form-control"
                      type="text"
                  />
                </div>
                <div class="col-md-12 mt-3">
                  <label>Date Of Service</label>
                  <date-picker
                      v-model="daterange"
                      append-to-body
                      confirm
                      format="DD MMM Y"
                      lang="en"
                      value-type="format"
                      range
                  ></date-picker>
                </div>
              </div>

            </div>

            <div class="col-md-8">
              <label>Terms of Delivery</label>
              <input
                  v-model="termOfDelivery"
                  class="form-control"
                  type="text"
                  readonly
              />
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-6" v-if="corpBranchID">
              <h5>{{corpBranchID.corpBranchName}}</h5>
              <address>
                {{corpBranchID.address}}<br>
                <b>GSTIN/UIN</b> : {{corpBranchID.gstNo}}<br>
                <b>PAN No.</b> : {{corpBranchID.panNo}}<br>
                <b>CIN</b> : {{corpBranchID.cinNo}}<br>
                <b>Contact</b> - {{corpBranchID.phoneNo}}
              </address>
            </div>
        <div class="col-md-6" v-if="restBranchID">
          <h5>{{restBranchID.restaurantName}}</h5>
          <address>
            {{restBranchID.address}}<br>
            <b>Vendor FSS LIC No.</b> : {{restBranchID.licenseNO}}<br>
          </address>
          </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 ">
      <div class="card">
        <div class="card-body">
          <h6 v-if="!isEdit">Add Services</h6>
          <div class="inner-repeater mb-4">
            <div class="inner mb-3">
              <div
                  v-for="(data, index) in taskArr"
                  :key="data.id"
                  class="inner mb-3 row no-gutter"
              >
                <div class="col-md-3">
                  <label class="form-label">Description of Service: *</label>
                  <input
                      v-model="data.service_name"
                      class="form-control"
                      type="text"
                      :title="data.service_name"
                  />

                </div>
                <div class="col-md-1" style="width:100px;">
                  <label class="form-label">GST Rate</label>
                  <multiselect
                      v-model="data.gstRate"
                      :options="gstRateArr"
                      :show-labels="false"
                      label="gst_percent"
                      track-by="id"
                      :title="data.gstRate"
                      @input="setSacCode(index)"
                  ></multiselect>
                </div>
                <div class="col-md-1" style="width:100px;">
                  <label class="form-label">SAC</label>
                  <input
                      v-model="data.sac"
                      class="form-control"
                      type="text"
                      readonly
                      :title="data.sac"
                  />
                </div>
                <div class="col-md-2" style="width:100px;">
                  <label class="form-label">Quantity</label>
                  <input
                      v-model="data.quantity"
                      class="form-control"
                      type="number"
                      :title="data.quantity"
                  />
                </div>
                <div class="col-md-1" style="width:100px;">
                  <label class="form-label">Rate</label>
                  <input
                      v-model="data.rate"
                      class="form-control"
                      type="text"
                      :title="data.rate"
                      @input="calculateAmount(index)"
                  />
                </div>
                <div class="col-md-1" style="width:140px;">
                  <label class="form-label">Unit</label>
                  <input
                      v-model="data.unit"
                      class="form-control"
                      type="text"
                     :title="data.unit"
                  />
                </div>
                <div class="col-md-2" style="width:115px;">
                  <label class="form-label">Amount</label>
                  <input
                      v-model="data.amount"
                      class="form-control"
                      type="text"
                      :title="data.amount"

                  />
                </div>

                <div class="col-md-1 pt-4 align-self-center d-grid">
                  <input
                      class="btn btn-themeBrown btn-block inner"
                      type="button"
                      value="Delete"
                      @click="deleteItem(index, data.reportTaskID)"
                  />
                </div>
              </div>
            </div>
            <input
                class="btn btn-themeOrange inner"
                type="button"
                value="Add New Service"
                @click="AddItem"
            />
          </div>

          <div class=" row" style="float:right;">
            <b-spinner
                v-if="loading"
                class="m-2 col-3"
                role="status"
                variant="primary"
            ></b-spinner>
            <button
                v-if="!isEdit"
                :disabled="loading"
                class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                type="button"
                @click="submitForm()"
            >
              Submit
            </button>
            <button
                v-if="isEdit"
                :disabled="loading"
                class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                type="button"
                @click="updateForm()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import {required} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

export default {
  name: "EventSalesForm" ,
  page:{
    title: "Add New Event Sale",
    meta: [
      {
        name: "Add New Event Sale",
        content: appConfig.description
      }
    ]
  },
  components: {Multiselect, Layout,PageHeader,DatePicker,},
  data() {
    return {
      title: "Add New Event Sale ",
      items: [
        {
          text: "Add New Event Sale",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],

      isEdit: false,
      isEditadd: false,
      status: "",
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      restBranchArr: [],
      restData: [],
      daterange: [],
      restBranchID: "",
      taskArr: [
        {
          service_name: "",
          sac: "",
          gstRate: 0,
          quantity:0,
          rate:0,
          unit:"",
          amount:0,
        }
      ],
      gstRateArr: [],
      state:"",
      stateArr:[],
      fssLicNo:11519005000913,
      termOfDelivery:"Intra State Supply",
      paymentMode:"",
      destination:"",
      corpBranchID: "",
      corpBranches: [],
  orderNo:"",
      countryArr: [],
      country: "",
      cityArr: [],
      city: "",
    };
  },

  validations: {
    restBranchID: {
      required
    }
  },
  mounted() {
    this.getStates();
    this.getAllCountry();
    this.getGSTSlabs();

  },
  methods: {
    getCorporateBranches() {
      this.axios.post(this.$loggedRole+"/get-corporate-branches",{
        city: this.city ? this.city.city : "",
      })
          .then(response => {
            this.corpBranches = response.data.data;
          })
          .catch(error => {
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    submitForm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0, 0);
        return;
      }
      else {
        this.loading = true;
               this.axios
            .post(this.$loggedRole+"/event-sales", {
              restaurant_branch_id: JSON.stringify(this.restBranchID),
              services: JSON.stringify(this.taskArr),
              corporate_branch_id:JSON.stringify(this.corpBranchID),
              state:JSON.stringify(this.state),
              payment_mode:this.paymentMode,
              destination:this.destination,
              service_date:this.daterange,
              order_no:this.orderNo,
              country: this.country ? this.country.country : "",
              city: this.city ? this.city.city : "",
            })
            .then(result => {
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success",
                title:'Event Sale'
              });
              this.resetForm();
            })

            .catch(error => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error"
              });
            });
      }
    },
    updateForm() {
      if (this.totalWeightagePercent > 100) {
        alert("Weightage should not exceed the limit!");
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        formData.append("questions", JSON.stringify(this.taskArr));
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);
        formData.append("fdtempId", this.templateID);

        this.axios
            .post(this.$loggedRole+"/feedback-template/update-feedback-questions", formData)
            .then(result => {
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success"
              });
              // setTimeout(() => {
              //   this.$router.push({ name: "fdbackViewTemplates" });
              // }, 2000);
              // location.reload();
            })
            .catch(error => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error"
              });
            });
      }
    },

    getAllRestaurantBranches() {
      this.axios
          .post(this.$loggedRole+"/get-restaurant-branches",{
            city: this.city ? this.city.city : "",
          })
          .then(result => {
            this.restBranchArr = result.data.data;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
resetForm(){
  this.restBranchID="";
  this.taskArr= [
    {
      service_name: "",
      sac: "",
      gstRate: 0,
      quantity:0,
      rate:0,
      unit:"",
      amount:0,
    }
  ];
  this.corpBranchID="";
  this.state="";
  this.paymentMode="";
  this.destination="";
  this.daterange=[];
  this.orderNo="";
},
    AddItem() {
      this.taskArr.push({
        service_name: "",
        sac: "",
        gstRate: 0,
        quantity:0,
        rate:0,
        unit:"",
        amount:0,
      });
    },
    deleteItem(index, reportTaskID) {
      if (reportTaskID > 0) {
        // this.loading = true;
        // if (confirm("Are you sure you want to delete this Question?")) {
        //   this.axios
        //       .post(this.$loggedRole+"/feedback-template/remove-question", {
        //         optionId: reportTaskID
        //       })
        //       .then(result => {
        //         this.loading = false;
        //         this.$swal({
        //           text: result.data.message,
        //           icon: "success"
        //         });
        //         this.getTemplateDetail();
        //       })
        //       .catch(error => {
        //         this.loading = false;
        //         this.$swal({
        //           text: error.response.data.message,
        //           icon: "error"
        //         });
        //       });
        // }
      } else {
        this.taskArr.splice(index, 1);
      }
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
          .post(this.$loggedRole+"/getAllCountry", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type
          })
          .then(response => {
            this.countryArr = response.data.data;
            // this.country = (this.countryArr) ? this.countryArr[0] : "";
            // this.getAllCities();
          });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
          .post(this.$loggedRole+"/getAllCities", {
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type
          })
          .then(response => {
            this.cityArr = response.data.data;
          });
    },
    getTemplateDetail() {
      this.loading = true;
      this.axios
          .get(this.$loggedRole+"/feedback-template/get-template-detail/" + this.templateID)
          .then(result => {
            this.taskArr = result.data.questions;
            this.reasonGroups = result.data.reason_groups;
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    getStates(){
      this.loading = true;
      this.axios
          .get(this.$loggedRole+"/get-states")
          .then(result => {
            this.loading = false;
            this.stateArr = result.data.data;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    getGSTSlabs(){
      this.loading = true;
      this.axios
          .get(this.$loggedRole+"/get-gst-slabs")
          .then(result => {
            this.loading = false;
            this.gstRateArr = result.data.data;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    setSacCode(index){
      const value = this.taskArr[index].gstRate.hsn_sac_code;
      this.$set(this.taskArr[index], "sac", `${value}`);
    },
    calculateAmount(index){
      const quantity = this.taskArr[index].quantity;
      const rate= this.taskArr[index].rate;
      const amount= quantity * rate;
      this.$set(this.taskArr[index], "amount", `${amount}`);
    }
  },
}
</script>
<style scoped></style>