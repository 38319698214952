<template>
  <Layout>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        <h5>Success!</h5>
        {{ alertMsg }}
      </b-alert>
    </div>

    <PageHeader :items="items" :title="title"/>
    <div
        v-if="this.$storageData.profile.empTypeID == 5"
        class="col-md-1"
        style="position: absolute;right: 58%;top: 75px;"
    >
      <label>Select Country </label>
      <multiselect
          v-model="country"
          :options="countryArr"
          :show-labels="false"
          label="country"
          track-by="country"
          @input="getCorporateCities()"
      ></multiselect>
    </div>
    <div
        v-if="this.$storageData.profile.empTypeID == 5"
        class="col-md-2"
        style="position: absolute;right: 41.5%;top: 75px;"
    >
      <label>Select City </label>
      <multiselect
          v-model="city"
          :options="cityArr"
          :show-labels="false"
          label="city"
          track-by="city"
          @input="getCorporateBranchByCorpID()"
      ></multiselect>
    </div>

    <div
        v-if="this.$storageData.profile.empTypeID == 5"
        class="col-md-3"
        style="position: absolute;right: 16.5%;top: 75px;"
    >
      <label>Select Corporate Branch </label>
      <multiselect
          v-model="branchID"
          :options="branches"
          :show-labels="false"
          label="corpBranchName"
          track-by="corpBranchID"
          @input="onchangeBranch()"
      ></multiselect>
    </div>

    <div class="col-lg-12" style="margin-top: 3%;">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <!-- <div class="col-md-1">  
                            <img :src="corpDetails.image ? corpDetails.image : $placeholder" class="profile-logo mb-2">
                        </div>
                        <div class="col-md-10">
                                <h4 class="card-title" style="margin-top: 17px;">
                                    {{corpDetails.corporateName}}</h4>

                                <p class="card-title-desc">
                                    {{corpDetails.branchName}}
                                </p>
                        </div> -->
            <div class="col-md-11">
              <img
                  :src="corpDetails.image ? corpDetails.image : $placeholder"
                  alt="thumb"
                  class="profile-logo mb-2"
                  style="width:auto; height:60px;float: left;margin-right: 10px;"
              />
              <h4 class="card-title" style="margin-top: 17px;">
                {{ corpDetails.corporateName }}
              </h4>

              <p class="card-title-desc">
                {{ corpDetails.branchName }}
              </p>
            </div>
            <div v-if="this.$storageData.login_type != 3" class="col-md-1">
              <button
                  class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light"
                  style="position: absolute;right: 2%;"
                  @click="addUserPage()"
              >
                Add User <i class="uil-user-plus"></i>
              </button>
            </div>
          </div>

          <b-tabs content-class="p-3 text-muted">
            <b-tab active class="border-0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Menu Access</span>
              </template>
              <div class="row">
                <div class="table-responsive col-md-8">
                  <table
                      class="table table-striped mb-0"
                      style="margin-top:15px;"
                  >
                    <thead>
                    <tr>
                      <td>Status</td>
                      <td>Menu Access</td>
                      <td>Display Name</td>
                      <td>Restaurant / Food Court</td>
                    </tr>
                    </thead>
                    <tbody v-for="data in menuTabTypeArr" :key="data.id">
                    <tr>
                      <td v-if="data.menuTabType" class="text-success">
                        Active
                      </td>
                      <td v-else>-</td>
                      <td>{{ data.menuAccessName }}</td>
                      <td>{{ data.displayName }}</td>

                      <td v-if="data.menuTabType == 'cafeteria'">
                        {{ data.restaurantName }}
                      </td>
                      <td v-else>{{ data.foodCourtName }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div
                    v-if="
                    this.$storageData.login_type != 3 &&
                      corpDetails.corporateBranchType == 1
                  "
                    class="col-md-1"
                >
                  <button
                      v-b-modal.modal-standard
                      class="col-md-1 btn btn-themeYellow w-md waves-effect waves-light mt-3"
                  >
                    Edit <i class="uil-edit-alt"></i>
                  </button>
                </div>
              </div>
            </b-tab>
            <b-tab title="Basic Details">
              <div class="row">
                <div class="table-responsive col-md-9">
                  <table
                      class="table table-striped mb-0"
                      style="margin-top:15px;"
                  >
                    <tbody>
                    <tr>
                      <td>Address</td>
                      <td>{{ corpDetails.address }}</td>
                    </tr>
                    <tr>
                      <td>GST No</td>
                      <td>{{ corpDetails.gstNO }}</td>
                    </tr>
                    <tr>
                      <td>Name on GST</td>
                      <td>{{ corpDetails.gstName }}</td>
                    </tr>
                    <tr>
                      <td>CIN NO</td>
                      <td>{{ corpDetails.cinNo }}</td>
                    </tr>
                    <tr>
                      <td>PAN NO</td>
                      <td>{{ corpDetails.panNo }}</td>
                    </tr>
                    <tr>
                      <td>Phone NO</td>
                      <td>{{ corpDetails.phoneNo }}</td>
                    </tr>
                    <tr>
                      <td>No Of Employees</td>
                      <td>{{ corpDetails.noOfEmp }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-3 mt-3">
                  <router-link
                      :to="{
                      name: 'corporateBranchForm',
                      params: { corporate: corpDetails, type: 'edit' }
                    }"
                  >
                     <i
                        class="uil uil-edit-alt btn btn-themeYellow w-md waves-effect waves-light btn"
                        title="Edit"
                    > Edit </i> </router-link>


                </div>
              </div>
            </b-tab>
            <b-tab title="Admins">
              <div class="row">
                <div class="table-responsive col-md-10">
                  <table
                      class="table table-striped mb-0"
                      style="margin-top:15px;"
                  >
                    <thead>
                    <tr>
                      <td>Name</td>
                      <td>Role</td>
                      <td>Email</td>
                      <td>Mobile No</td>
                      <td>Action</td>
                    </tr>
                    </thead>
                    <tbody v-for="data in adminArr" :key="data.id">
                    <tr>
                      <td>{{ data.corpAdminName }}</td>

                      <td>{{ data.role }}</td>
                      <td>{{ data.emailID }}</td>
                      <td>{{ data.phoneNO }}</td>
                      <td>
                        <router-link
                            :to="{
                       name: 'corporateAdminForm',
                        params: { corporateID: corporateID,
                        corpBranchID: corpBranchID,admin_id:data.admin_id,type:'edit'}
                      }"
                            v-if="$can('edit corporate admin')"
                        >
                          <button type="button" class="btn btn-outline-warning btn-sm">
                            <i
                                class="uil uil-edit-alt"
                                title="Edit"
                                style="font-size: 19px"
                            ></i>

                          </button>
                        </router-link>

                        &nbsp;
                        <button type="button"
                                v-if="$can('delete corporate admin')"
                                class="btn btn-outline-danger btn-sm" @click="deleteAdmin(data.admin_id)">
                          <i
                              class="uil uil-trash"
                              title="Delete"
                              style="font-size: 19px"
                          ></i>
                        </button>


                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div v-if="$can('create corporate admin')" class="col-md-2">
                  <router-link
                      :to="{
                     name: 'corporateAdminForm',
                      params: {
                        corporateID: corporateID,
                        corpBranchID: corpBranchID,
                        admin_id:0,
                        type:'create'
                      }
                    }"
                  >
                    <button
                        class="btn btn-themeYellow w-md waves-effect waves-light mt-3"
                    >
                      Add Admin
                    </button>
                  </router-link>
                </div>
              </div>
            </b-tab>

            <b-tab title="Departments">
              <div class="row">
                <div class="table-responsive col-md-6">
                  <table
                      class="table table-striped mb-0"
                      style="margin-top:15px;"
                  >
                    <thead>
                    <tr>
                      <td>Departments</td>
                    </tr>
                    </thead>
                    <tbody v-for="data in departmentListArr" :key="data.id">
                    <tr>
                      <td>{{ data.departmentName }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="this.$storageData.login_type != 3" class="col-md-3">
                  <button
                      v-b-modal.modal-department
                      class="btn btn-themeYellow w-md waves-effect waves-light mt-3"
                  >
                    Add Department
                  </button>
                </div>
              </div>
            </b-tab>

            <b-tab
                v-if="
                this.$storageData.login_type == 1 ||
                  this.$storageData.login_type == 3
              "
                title="Configure Wallet"
            >
              <div v-if="this.$storageData.login_type != 3" class="col-lg-8">
                <div class="card">
                  <div class="card-body">
                    <div class="mt-3">
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="walletValidation"
                            class="custom-radio mb-3"
                            plain
                            value="4"
                        >
                          Not Configured
                        </b-form-radio
                        >
                      </div>
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="walletValidation"
                            class="custom-radio mb-3"
                            plain
                            value="1"
                        >
                          Daily: Expires on same day at 11:59pm
                        </b-form-radio
                        >
                      </div>
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="walletValidation"
                            class="mb-3"
                            plain
                            value="2"
                        >Weekly: Expires on every Sunday at
                          11:55pm
                        </b-form-radio
                        >
                      </div>
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="walletValidation"
                            class="mb-3"
                            plain
                            value="3"
                        >Monthly: Expires on every month end (Calendar
                          month)
                        </b-form-radio
                        >
                      </div>

                      <div class=" row">
                        <button
                            class="col-1 btn btn-themeYellow w-md waves-effect waves-light"
                            type="button"
                            @click="updateWalletConfig()"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 mt-5">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="settings"
                            class="custom-radio mb-3"
                            plain
                            value="1"
                        >
                          Add manually
                        </b-form-radio
                        >
                      </div>

                      <div v-if="settings == 1" class="col-md-6 mb-3">
                        <label>Choose Admins* </label>
                        <multiselect
                            v-model="corpAdminID"
                            :multiple="true"
                            :options="adminsArr"
                            :show-labels="false"
                            label="adminName"
                            track-by="corpAdminID"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-radio
                            v-model="settings"
                            class="custom-radio mb-3"
                            plain
                            value="2"
                        >
                          Add on Attendance
                        </b-form-radio
                        >
                      </div>

                      <div v-if="settings == 2" class="mb-3 col-md-4">
                        <label class="form-label" for="formrow-amount-input"
                        >Enter amount to add *</label
                        >
                        <div class="input-group">
                          <div class="input-group-text">₹</div>
                          <input
                              id="formrow-amount-input"
                              v-model="amount"
                              class="form-control"
                              name="amount"
                              type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class=" row">
                      <b-spinner
                          v-if="loading"
                          class="m-2 col-3"
                          role="status"
                          variant="primary"
                      ></b-spinner>
                      <button
                          :disabled="loading"
                          class="col-1 btn btn-themeYellow w-md waves-effect waves-light"
                          type="button"
                          @click="walletSettings()"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab v-if="this.$storageData.login_type == 1" title="Sodexo">
              <div class="col-lg-8">
                <div v-if="isLoading === 1" class="text-center text-danger my-2 mt-2">
                  <b-spinner class="align-middle m-2"></b-spinner>
                  <strong>Updating Please wait...</strong>
                </div>
                <div class="card">
                  <div class="card-body">
                    <div class="mt-3">
                      <div class="col-12">
                        <div
                            class="form-check form-switch form-switch-md mb-2"
                            dir="ltr"
                        >
                          <input
                              id="switchSodexo"
                              v-model="isSodexoApproved"
                              class="form-check-input"
                              type="checkbox"
                              @change="updateSodexoStatus()"/>
                          <label class="form-check-label" for="switchSodexo"
                          >Activate Pay By Sodexo</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <!-- MOdal for menu access -->
          <b-modal
              id="modal-standard"
              size="lg"
              title="Menu Access"
              title-class="font-16"
          >
            <div
                v-for="(data, index) in menuTabTypeArr"
                :key="data.id"
                class="row"
            >
              <div class="row" style="margin-bottom: 7px">
                <div class="col-md-2 mt-4">
                  <b-form-checkbox
                      v-model="checkBox[index]"
                      checked
                      class="mb-3"
                      plain
                  >{{ data.menuAccessName }}
                  </b-form-checkbox
                  >
                  <!-- @change="isChecked(index)" -->
                </div>

                <div class="col-md-3">
                  <label> Display Name: </label>
                  <input
                      v-model="displayName[index]"
                      class="form-control"
                      name="corporateCode"
                      placeholder="Enter display name"
                      type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label> Display Sequence: </label>
                  <input
                      v-model="finalStepNo[index]"
                      class="form-control"
                      name="corporateCode"
                      placeholder="Enter display Sequence"
                      type="text"
                  />
                </div>
                <!-- data.menuTabType=='cafeteria' -->
                <div v-if="index == 0" class="col-md-4">
                  <label> Select Cafeteria </label>
                  <multiselect
                      v-model="restBranchID"
                      :options="cafeteriaList"
                      :show-labels="false"
                      label="restaurantName"
                      track-by="restBranchID"
                  ></multiselect>
                </div>
                <div v-if="index == 1" class="col-md-4">
                  <label> Select Food Court </label>
                  <multiselect
                      v-model="foodCourtID"
                      :options="foodCourtList"
                      :show-labels="false"
                      label="foodCourtName"
                      track-by="foodCourtID"
                  ></multiselect>
                </div>
                <hr style="height: 1px;color: #b1b1b1;margin: 5px;"/>
              </div>
            </div>
            <template #modal-footer style="border-top:none !important;">
              <div class="w-100">
                <b-button
                    size="sm"
                    style="float:right;"
                    variant="primary"
                    @click="addMenuAccess"
                >
                  Save
                </b-button>
              </div>
            </template>
          </b-modal>
          <!-- MOdal for menu access end -->

          <!-- MOdal for add department -->
          <b-modal
              id="modal-department"
              title="Add Department"
              title-class="font-16"
          >
            <div class="col-md-12 mb-3">
              <label class="form-label" for="formrow-departmentName-input"
              >Department Name*</label
              >
              <input
                  id="formrow-departmentName-input"
                  v-model="departmentName"
                  class="form-control"
                  type="text"
              />
            </div>
            <p style="color:green">{{ departmentData.message }}</p>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                    size="sm"
                    style="float:right;"
                    variant="primary"
                    @click="submitDepartment"
                >
                  Save
                </b-button>
              </div>
            </template>
          </b-modal>
          <!-- MOdal for department end -->
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import helperFile from "../../../helpers/helper";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Corporate Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Multiselect,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Corporate Profile",
      items: [
        {
          text: "Corporate Profile",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],

      status: "",
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      corpDetails: "",
      city: "",
      cityArr: [],
      countryArr: [],
      country: "",
      branches: [],
      branchID: "",
      isLoading: 0,
      displayName: [],
      finalStepNo: [],
      checkBox: [],
      enumArr: [],
      menuAccessEnum: [],
      corpBranchID: "",
      restBranchID: "",
      cafeteriaList: [],
      foodCourtList: [],
      nameDisabled: true,
      menuTabTypeArr: [],
      adminArr: [],
      foodCourtID: "",
      departmentName: "",
      departmentListArr: [],
      departmentData: [],
      walletValidation: "4",
      alertMsg: "",
      adminsArr: [],
      corpAdminID: [],
      settings: "1",
      amount: [],
      corporateID: "",
      isSodexoApproved: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      sessionStorage.setItem("data", this.id); // Save to sessionStorage
    }
  },
  methods: {
    deleteAdmin(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you really want to delete this corporate user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!"
      }).then((result)=> {
        if (result.isConfirmed) {
          this.axios
              .post(this.$loggedRole+
                  "/delete-corporate-admin",
                  { id: id },
              )
              .then((result) => {
                this.$swal({
                  text: result.data.message,
                  icon: "success",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000
                }).then(()=>{
                  location.reload();
                });
              })
              .catch(error => {
                this.loading = false;
                this.$swal({
                  text: error.response.data.message,
                  icon: "error",
                });
              });
        }
      });


    },
    addMenuAccess() {
      if (
          this.checkBox[0] == true &&
          this.restBranchID.restBranchID == undefined
      ) {
        alert("Please Select Cafeteria");
        return;
      }
      if (
          this.checkBox[1] == true &&
          this.foodCourtID.foodCourtID == undefined
      ) {
        alert("Please Select Food Court");
        return;
      }
      this.$root.$emit("bv::hide::modal", "modal-standard");
      let formData = new FormData();
      formData.append("enumArr", JSON.stringify(this.enumArr));
      formData.append("checkBox", JSON.stringify(this.checkBox));
      formData.append("displayName", JSON.stringify(this.displayName));
      formData.append("stepNo", JSON.stringify(this.finalStepNo));
      formData.append("restBranchID", this.restBranchID.restBranchID);
      formData.append("foodCourtID", this.foodCourtID.foodCourtID);
      formData.append("corpBranchID", this.corpBranchID);

      this.axios.post(this.$loggedRole + "/addCorporateMenuAccess", formData).then(response => {
        //this.corpDetails = response.data.data;
        this.menuTabTypeArr = response.data.data;
      });
      //}
    },
    getCorporateBranchDetailsByID(corpBranchID) {
      this.axios
          .post(this.$loggedRole + "/corporateProfileInfo", {corpBranchID: corpBranchID})
          .then(response => {
            this.corpDetails = response.data.data;
            this.corporateID = this.corpDetails.corporateID;
            this.getEmployees();
            // this.corpMealAccessArr = response.data.data.corpMealAccessArr;
            this.menuTabTypeArr = response.data.data.corpMealAccessArr;
            this.checkBox = response.data.data.menuTabTypeArr;
            this.displayName = response.data.data.finalDisplayName;
            this.enumArr = response.data.data.enumArr;
            this.finalStepNo = response.data.data.finalStepNo;
            this.restBranchID = response.data.data.restData;
            this.foodCourtID = response.data.data.foodCourtData;
            this.adminArr = response.data.data.adminArr;
            this.departmentListArr = response.data.data.depData;
            this.walletValidation = this.corpDetails.walletValidation;
            this.settings = this.corpDetails.walletSetting;
            this.amount = this.corpDetails.walletAmount;
            this.corpAdminID = this.corpDetails.walletAccessAdmins;
            this.isSodexoApproved = this.corpDetails.isSodexoApproved == 1 ? true : false;
            this.nearbyCafeteria = helperFile.getNearByRestaurantOrCafe;
            this.nearbyCafeteria(
                this.corpDetails.latitude,
                this.corpDetails.longitude,
                2
            ); // flag =>restaurant only, 2=>cafeteria
          });
    },

    addUserPage() {
      this.$router.push({
        name: "corporateUsersForm",
        params: {id: this.corpBranchID}
      });
    },
    getFoodCourtList() {
      this.axios.post(this.$loggedRole + "/getFoodCourtList").then(result => {
        this.foodCourtList = result.data.data;
      });
    },
    submitDepartment() {
      if (this.departmentName == "") {
        alert("Please Enter Department Name");
        return;
      }

      let formData = new FormData();
      formData.append("createdBy", this.$storageData.profile.pid);
      formData.append("loginTypeID", this.$storageData.login_type);
      formData.append("corpBranchID", this.corpBranchID);
      formData.append("departmentName", this.departmentName);

      this.axios.post(this.$loggedRole + "/addCorporateDepartment", formData).then(response => {
        this.departmentData = response.data;
        this.departmentListArr = response.data.data;
        setTimeout(() => {
          this.$root.$emit("bv::hide::modal", "modal-department");
          this.departmentData.message = "";
          this.departmentName = "";
        }, 1000);
      });
    },

    updateWalletConfig() {
      this.axios
          .post(this.$loggedRole + "/updateWalletConfig", {
            walletValidation: this.walletValidation,
            corpBranchID: this.corpBranchID
          })
          .then(() => {
            this.showDismissibleAlert = true;
            this.alertMsg = "Wallet Configuration updated successfully";
            setTimeout(() => {
              this.showDismissibleAlert = false;
            }, 2000);
          });
    },

    walletSettings() {
      this.submitted = true;
      this.loading = true;
      let formData = new FormData();
      formData.append("amount", this.amount);
      formData.append("corpAdminIDs", JSON.stringify(this.corpAdminID));
      formData.append("settings", this.settings);
      formData.append("corporateID", this.corpDetails.corporateID);
      formData.append("corpBranchID", this.corpBranchID);
      this.axios
          .post(this.$loggedRole + "/addWalletSettings", formData)
          .then(result => {
            this.status = result.data.data;
            this.loading = false;
            this.showDismissibleAlert = true;
            this.alertMsg = "Wallet Configuration updated successfully";
            setTimeout(() => {
              this.showDismissibleAlert = false;
            }, 2000);
          })
          .catch(() => {
            this.loading = false;
            alert("Something went Wrong");
          });
    },
    updateSodexoStatus() {
      this.submitted = true;
      this.loading = true;
      this.isLoading = 1;
      let formData = new FormData();
      formData.append("isSodexoApproved", this.isSodexoApproved);
      formData.append("corporateID", this.corpDetails.corporateID);
      formData.append("corpBranchID", this.corpBranchID);
      this.axios
          .post(this.$loggedRole + "/updateSodexoStatus", formData)
          .then(result => {
            this.status = result.data.data;
            this.isSodexoApproved = result.data.sodexo == 1 ? true : false;
            this.loading = false;
            this.isLoading = 0;
            this.showDismissibleAlert = true;
            this.alertMsg = result.data.message;
            setTimeout(() => {
              this.showDismissibleAlert = false;
            }, 2000);
          })
          .catch((error) => {
            this.loading = false;
            this.isLoading = 0;
            console.log(error.response);
            alert(error.response.data.message);
          });
    },

    getEmployees() {
      this.adminsArr = [];
      this.axios
          .post(this.$loggedRole + "/getEmployeesByCorporateID", {
            corporateID: this.corpDetails.corporateID
          })
          .then(result => {
            this.adminsArr = result.data.data;
          });
    },
    getCorporateCountry() {
      this.axios
          .post(this.$loggedRole + "/getCorporateCountry", {
            corporateID: this.$storageData.profile.corporateID
          })
          .then(response => {
            this.countryArr = response.data.data;
            this.country = this.countryArr ? this.countryArr[0] : "";
            this.getCorporateCities();
          });
    },

    getCorporateCities() {
      this.axios
          .post(this.$loggedRole + "/getCorporateCities", {
            corporateID: this.$storageData.profile.corporateID,
            country: this.country ? this.country.country : ""
          })
          .then(response => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            this.getCorporateBranchByCorpID();
          });
    },
    getCorporateBranchByCorpID() {
      this.axios
          .post(this.$loggedRole + "/getCorporateBranchByCorpID", {
            corporateID: this.$storageData.profile.corporateID,
            city: this.city ? this.city.city : ""
          })
          .then(response => {
            this.branches = response.data.data;
            this.branchID = this.branches[0];
            this.corpBranchID = this.branchID.corpBranchID;
            this.getCorporateBranchDetailsByID(this.corpBranchID);
          });
    },
    onchangeBranch() {
      this.corpBranchID = this.branchID.corpBranchID;
      this.getCorporateBranchDetailsByID(this.corpBranchID)
    }
  },
  mounted() {

    if (this.$storageData.profile.corpBranchID === undefined || this.$storageData.profile.corpBranchID == 0) {
      this.corpBranchID = sessionStorage.getItem("data");
    } else {
      this.corpBranchID = this.$storageData.profile.corpBranchID;
    }
    this.getFoodCourtList();
    this.$storageData.profile.empTypeID == 5
        ? this.getCorporateCountry()
        : this.getCorporateBranchDetailsByID(this.corpBranchID);
  },

  middleware: "authentication"
};
</script>
<style scoped>
.profile-logo {
  height: 71px;
  width: 71px;
  background-color: #f6f6f6;
  padding: 3px;
  object-fit: contain;
}

.table-striped td {
  border: 1px solid gainsboro !important;
}

.modal-footer {
  border-top: none !important;
}
</style>
