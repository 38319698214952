<template>
  <Layout>
    <PageHeader :items="items" :title="title" />

    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        Template Created Successfully!
      </b-alert>
      <b-alert
        v-model="showDismissibleQuestAlert"
        dismissible
        variant="success"
      >
        Question Deleted Successfully!
      </b-alert>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-2">
              <label>Select Country </label>
              <multiselect
                v-model="country"
                :disabled="isEdit"
                :options="countryArr"
                :show-labels="false"
                label="country"
                track-by="country"
                @input="getAllCities"
              ></multiselect>
            </div>
            <div class="col-md-2">
              <label>Select City </label>
              <multiselect
                v-model="city"
                :disabled="isEdit"
                :options="cityArr"
                :show-labels="false"
                label="city"
                track-by="city"
                @input="getPrincipleAndCorporates()"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label>Select Principle Branch </label>
              <multiselect
                v-model="principleBranchID"
                :disabled="isEdit"
                :options="principleBranches"
                :show-labels="false"
                label="principleBranchName"
                track-by="principleBranchID"
                @input="getRestaurantByPrincipleBranch"
              ></multiselect>
            </div>
            <div class="col-md-4">
              <label class="form-label">Select Corporate Branch </label>
              <multiselect
                v-model="corpBranchID"
                :disabled="isEdit"
                :options="corpBranches"
                :show-labels="false"
                label="corpBranchName"
                track-by="corpBranchID"
                @input="getRestaurantByCorporateBranch"
              ></multiselect>
              <!-- @input="onchangeBranch();" -->
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-4">
              <label class="form-label" for="form row-endTime-input"
                >Select Restaurant Branch*</label
              >
              <multiselect
                v-model="restBranchID"
                :class="{
                  'is-invalid': submitted && $v.restBranchID.$error
                }"
                :disabled="isEdit"
                :options="restBranchArr"
                label="restaurantName"
                track-by="restBranchID"
                @input="getReasonGroups()"
              ></multiselect>
              <div
                v-if="submitted && $v.restBranchID.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.restBranchID.required"
                  >This value is required.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 ">
      <div class="card">
        <div class="card-body">
          <h6 v-if="!isEdit">Add Questions</h6>
          <h6 v-else>Edit/Add Questions</h6>
          <div class="inner-repeater mb-4">
            <div class="inner mb-3">
              <div
                v-for="(data, index) in taskArr"
                :key="data.id"
                class="inner mb-3 row"
              >
                <div class="col-md-3">
                  <label class="form-label">Question: *</label>
                  <input
                    v-model="data.enteredquestion"
                    :disabled="data.reportTaskID > 0 ? '' : disabled"
                    class="form-control"
                    type="text"
                  />
                  <!--                  <span v-if="data.enteredquestion == ''"-->
                  <!--                    >This value is required.</span-->
                  <!--                  >-->
                </div>
                <div class="col-md-3">
                  <label class="form-label">Weightage *</label>
                  <multiselect
                    v-model="data.weightage"
                    :options="weightageArr"
                    :show-labels="false"
                  ></multiselect>
                </div>
                <div class="col-md-3">
                  <label class="form-label">Reason Group *</label>
                  <multiselect
                    v-model="data.reason_group"
                    :options="reasonGroups"
                    :show-labels="false"
                    label="title"
                    track-by="id"
                  ></multiselect>
                </div>
                <div class="col-lg-3 pt-4 align-self-center d-grid">
                  <input
                    class="btn btn-themeBrown btn-block inner"
                    type="button"
                    value="Delete"
                    @click="deleteItem(index, data.reportTaskID)"
                  />
                </div>
              </div>
            </div>
            <input
              v-if="this.taskArr.length < 5"
              class="btn btn-themeOrange inner"
              type="button"
              value="Add New Question"
              @click="AddItem"
            />
          </div>
          <div class="row">
            <div class="col-md-3">
              <h6>Total:</h6>
            </div>
            <div class="col-md-3">
              <h6>{{ totalWeightagePercent }} %</h6>
            </div>
          </div>

          <div class=" row" style="float:right;">
            <b-spinner
              v-if="loading"
              class="m-2 col-3"
              role="status"
              variant="primary"
            ></b-spinner>
            <button
              v-if="!isEdit"
              :disabled="loading"
              class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
              type="button"
              @click="submitForm()"
            >
              Submit
            </button>
            <button
              v-if="isEdit"
              :disabled="loading"
              class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
              type="button"
              @click="updateForm()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Feedback Form",
    meta: [
      {
        name: "Feedback Form",
        content: appConfig.description
      }
    ]
  },

  components: {
    Multiselect,
    Layout,
    PageHeader
  },

  data() {
    return {
      title: "Add Feedback ",
      items: [
        {
          text: "Add Feedback",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],
      isEdit: false,
      isEditadd: false,
      status: "",
      submitted: false,
      showDismissibleAlert: false,
      showDismissibleQuestAlert: false,
      loading: false,
      questions: "",
      restBranchArr: [],
      restData: [],
      feedbackTempId: 0,
      restBranchID: "",
      taskArr: [
        {
          enteredquestion: "",
          weightage: "",
          reason_group: ""
        }
      ],
      weightageArr: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
        67,
        68,
        69,
        70,
        71,
        72,
        73,
        74,
        75,
        76,
        77,
        78,
        79,
        80,
        81,
        82,
        83,
        84,
        85,
        86,
        87,
        88,
        89,
        90,
        91,
        92,
        93,
        94,
        95,
        96,
        97,
        98,
        99,
        100
      ],
      optiontypeArr: ["Smiley Rating", "Heart Rating", "Star Rating"],
      reasonGroups: [],
      country: "",
      countryArr: [],
      city: "",
      cityArr: [],
      principleBranchID: "",
      principleBranches: [],
      corpBranchID: "",
      corpBranches: [],
      templateID: 0
    };
  },

  validations: {
    restBranchID: {
      required
    }
  },

  computed: {
    totalquestionsTypePercent() {
      return this.taskArr.reduce(
        (acc, item) => acc + parseInt(item.questionsType),
        0
      );
    },
    totalScore() {
      return this.taskArr.reduce(
        (acc, item) => acc + parseInt(item.maxScore),
        0
      );
    },
    totalWeightagePercent() {
      return this.taskArr.reduce(
        (acc, item) => acc + parseInt(item.weightage),
        0
      );
    }
  },

  methods: {
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
        .post(this.$loggedRole+"/getAllCountry", {
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.countryArr = response.data.data;
        })
        .catch(error => {
          this.$swal({
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getAllCities() {
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
        });
    },
    getPrincipleAndCorporates() {
      this.getPrincipleBranches();
      this.getCorporateBranches();
    },
    getPrincipleBranches() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.principleBranches = response.data.data;
        })
        .catch(error => {
          this.$swal({
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getCorporateBranches() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/get-corporate-branches", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.corpBranches = response.data.data;
        })
        .catch(error => {
          this.$swal({
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getRestaurantByPrincipleBranch() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/principle/restaurants", {
          city: this.city ? this.city.city : "",
          principleBranchID: this.principleBranchID
            ? this.principleBranchID.principleBranchID
            : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.restBranchArr = response.data.data;
        })
        .catch(error => {
          this.$swal({
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getRestaurantByCorporateBranch() {
      this.axios
        .post(this.$loggedRole+"/feedback-template/corporate/restaurants", {
          city: this.city ? this.city.city : "",
          corpBranchID: this.corpBranchID ? this.corpBranchID.corpBranchID : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.restBranchArr = response.data.data;
        })
        .catch(error => {
          this.$swal({
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    submitForm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0, 0);
        return;
      } else if (this.totalWeightagePercent > 100) {
        alert("Weightage should not exceed the limit!");
        return;
      } else if (
        this.principleBranchID.length <= 0 &&
        this.corpBranchID.length <= 0
      ) {
        alert("Please select either principle branch or corporate branch.");
        return;
      } else if (
        this.principleBranchID.length > 0 &&
        this.corpBranchID.length > 0
      ) {
        alert(
          "You can create reason groups for either principle branch or corporate branch."
        );
        return;
      } else {
        let formData = new FormData();

        formData.append("restBranchID", this.restBranchID.restBranchID);
        formData.append("taskArr", JSON.stringify(this.taskArr));
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);
        formData.append(
          "corporate_branch_id",
          this.corpBranchID ? this.corpBranchID.corpBranchID : ""
        );
        formData.append(
          "principle_branch_id",
          this.principleBranchID ? this.principleBranchID.principleBranchID : ""
        );

        if (this.isEdit == 1 || this.isEditadd == 1) {
          formData.append("feedbackTempId", this.feedbackTempId);
        }

        this.axios
          .post(this.$loggedRole+"/addFeedbackTemplate", formData)
          .then(result => {
            this.status = result.data.data;
            this.showDismissibleAlert = true;
            this.loading = false;
            //
            // setTimeout(() => {
            //   this.$router.push({ name: "fdbackViewTemplates" });
            // }, 2000);
            // location.reload();
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
      }
    },
    updateForm() {
      if (this.totalWeightagePercent > 100) {
        alert("Weightage should not exceed the limit!");
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        formData.append("questions", JSON.stringify(this.taskArr));
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);
        formData.append("fdtempId", this.templateID);

        this.axios
          .post(this.$loggedRole+"/feedback-template/update-feedback-questions", formData)
          .then(result => {
            this.loading = false;
            this.$swal({
              text: result.data.message,
              icon: "success"
            });
            // setTimeout(() => {
            //   this.$router.push({ name: "fdbackViewTemplates" });
            // }, 2000);
            // location.reload();
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
      }
    },

    getAllRestaurantBranches() {
      this.axios
        .post(this.$loggedRole+"/getAllRestaurantBranches", {
          restBranchID:
            this.$storageData.login_type == 2 &&
            this.$storageData.profile.empTypeID != 11
              ? this.$storageData.profile.restBranchID
              : 0, // this.$storageData.profile.restBranchID,
          restID: this.$storageData.profile.restID
        })
        .then(result => {
          this.restBranchArr = result.data.data;
        });
    },

    AddItem() {
      this.taskArr.push({
        enteredquestion: "",
        weightage: "",
        reason_group: ""
      });
    },

    // getCorporateMealPlan() {
    //   this.axios
    //     .post(this.$loggedRole+"/getCorporateMealPlanFormData", {
    //       corpMealPlanID: this.corpMealPlanID
    //     })
    //     .then(result => {
    //       this.mealPlanData = result.data.data;
    //       this.corpBranchID = {
    //         corpBranchID: this.mealPlanData.corpBranchID,
    //         corpBranchName: this.mealPlanData.corpBranchName
    //       };
    //       this.corpBranchID.corpBranchID = this.mealPlanData.corpBranchID;
    //       this.getCorporateCafeteria(this.corpBranchID.corpBranchID);
    //       this.getHolidayList(this.mealPlanData.corporateID);
    //       this.applicableTo = this.mealPlanData.applicableTo;
    //       this.showSellingPrice = this.mealPlanData.showSellingPrice;
    //       this.startDate = this.mealPlanData.startDate;
    //       this.endDate = this.mealPlanData.endDate;
    //       this.holidayID = result.data.holidayArr;
    //       this.restBranch = this.mealPlanData.restDetails;
    //       this.selectedDepartment = result.data.depData;
    //       this.selectedEmployees = result.data.selectedUsers;
    //       this.taskArr =
    //         result.data.mealPlans != ""
    //           ? result.data.mealPlans
    //           : [
    //               {
    //                 id: 1,
    //                 mealTypeName: "",
    //                 discountType: "",
    //                 discountInRs: "",
    //                 discountPercent: "",
    //                 maxDiscountCapping: "",
    //                 corpMealID: 0
    //               }
    //             ];
    //     });
    // },

    deleteItem(index, reportTaskID) {
      if (reportTaskID > 0) {
        this.loading = true;
        if (confirm("Are you sure you want to delete this Question?")) {
          this.axios
            .post(this.$loggedRole+"/feedback-template/remove-question", {
              optionId: reportTaskID
            })
            .then(result => {
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success"
              });
              this.getTemplateDetail();
            })
            .catch(error => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error"
              });
            });
        }
      } else {
        this.taskArr.splice(index, 1);
      }
    },

    getFdbacktemplatebyrestbranch(restBranchIDn) {
      this.axios
        .post(this.$loggedRole+"/getFeedbackTemplatesByrestBranchID", {
          restBranchID: restBranchIDn
        })
        .then(result => {
          var lenghtarrres = result.data.data.length;

          if (lenghtarrres > 0) {
            var restDatanew = result.data.data[0].feedbackTempId;
            this.feedbackTempId = restDatanew;
            this.isEditadd = 1;
            this.getFeedbaackTemplate();
          } else {
            this.feedbackTempId = 0;
            this.isEditadd = 0;
            this.taskArr = [
              {
                enteredquestion: "",
                weightage: "",
                reason_group: ""
              }
            ];
          }
        });
    },

    getTemplateDetail() {
      this.loading = true;
      this.axios
        .get(this.$loggedRole+"/feedback-template/get-template-detail/" + this.templateID)
        .then(result => {
          this.taskArr = result.data.questions;
          this.reasonGroups = result.data.reason_groups;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$swal({
            text: error.response.data.message,
            icon: "error"
          });
        });
    },

    getReasonGroups() {
      if (this.principleBranchID.length <= 0 && this.corpBranchID.length <= 0) {
        alert("Please select either principle branch or corporate branch.");
        return;
      } else if (
        this.principleBranchID.length > 0 &&
        this.corpBranchID.length > 0
      ) {
        alert(
          "You can create reason groups for either principle branch or corporate branch."
        );
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        formData.append(
          "corporate_branch_id",
          this.corpBranchID ? this.corpBranchID.corpBranchID : ""
        );
        formData.append(
          "principle_branch_id",
          this.principleBranchID ? this.principleBranchID.principleBranchID : ""
        );
        formData.append(
          "restaurant_branch_id",
          this.restBranchID ? this.restBranchID.restBranchID : ""
        );
        formData.append("loginTypeID", this.$storageData.login_type);

        this.axios
          .post(this.$loggedRole+"/feedback-template/get-reason-groups-by-restaurant", formData)
          .then(result => {
            this.loading = false;
            this.reasonGroups = result.data.data;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
      }
    }
  },
  created() {
    // this.corpMealPlanID = this.$route.params.id;
    //
    // if (this.corpMealPlanID) {
    //   sessionStorage.setItem("corpMealPlanID", this.corpMealPlanID); // Save to sessionStorage
    // }

    if (this.$route.params.type == "edit") {
      this.isEdit = true;
      this.title = "Edit Feedback Template";
      this.templateID = this.$route.params.id;
      this.getTemplateDetail();
    } else {
      this.isEdit = false;
    }
  },

  mounted() {
    this.getAllCountry();
    //     if (this.isEdit == 1 && sessionStorage.getItem("corpMealPlanID") > 0) {
    // }
  },

  middleware: "authentication"
};
</script>
<style>
.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}

.newicon {
  font-size: 20px;
}
</style>
