<script>
import Layout from "../../layouts/main.vue";
import appConfig from "@/app.config";
import PageHeader from "../../../components/page-header.vue";
import { toWords } from 'number-to-words';

export default {
  name: "EventSaleDetail",
  page: {
    title: "Event Sale Preview",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {PageHeader, Layout},
  data() {
    return {
      title: "Event Sale Preview",
      items: [
        {
          text: "Preview",
          href: "/"
        },

      ],
      id: "",
      detail: "",
      fssLicNo: 11519005000913,
      termOfDelivery: "Intra State Supply",
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "service_name",
      sortDesc: false,
      totalRows: 1,
      fields: [
        {
          key: "service_name",
          label:"Description Of Service",
          sortable: true,
          tdClass: "align-center",
        },
        {
          key: "gst_percentage",
          sortable: true,
          tdClass: "align-center",
        },
        {
          key: "sac",
          sortable: true,
          tdClass: "align-center",
        },
        {
          key: "quantity",
          sortable: true,
          tdClass: "align-center",
        },

        {
          key: "rate",
          sortable: true,
          tdClass: "align-center",
        } ,
        {
          key: "unit",
          sortable: true,
          tdClass: "align-center",
        } ,
        {
          key: "amount",
          sortable: true,
          tdClass: "align-center",
        }
      ],
    };
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      sessionStorage.setItem("evs_id", this.id); // Save to sessionStorage
    }
  },
  mounted() {
    this.id = sessionStorage.getItem("evs_id");
    this.getPreview();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getPreview() {
      this.axios
          .get(this.$loggedRole + "/event-sales/" + this.id)
          .then((result) => {
            this.detail = result.data.data;
          })
          .catch((error) => {
            this.$swal({
              text: error.response.data.message,
              icon: "error"
            });
          });
    },
    numToWord(number) {
      let words= toWords(number);
      return words
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }
  }

}
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="card" style="margin-top:-30px">
      <h5 style="padding-top: 10px; margin-left: 10px"
      >
        {{ title }}
      </h5>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h6>{{detail.ck_bank.name}}</h6>
              <address>
                {{detail.state_request.cloudKitchBillingAddress}}<br>
                <b>GSTIN/UIN</b> : {{detail.state_request.cloudKitchGstNo}}<br>
                <b>PAN No.</b> : {{detail.state_request.panNo}}<br>
                <b>State Name</b> : {{detail.state_request.state}}, Code : {{detail.state_request.shortCode}}<br>
                <b>CIN</b> : {{detail.state_request.cinNo}}<br>
                <b>FSS LIC NO</b> :{{detail.ck_bank.fss_lic_no}}<br>
                <b>Contact</b> - {{detail.state_request.ckPhoneNo}}  <b>Email ID</b> - {{detail.state_request.ckEmailID}}
              </address>
            </div>
            <div class="col-md-3">
              <p><b>Proforma Invoice No.</b><br>
                {{detail.invoice_no}}
              </p>

            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-12">
                  <p><b>Dated</b><br>
                    {{detail.createdOn}}
                  </p>
                </div>
                <div class="col-12">
                  <p><b>Mode/Term of Payment</b><br>
                    {{detail.payment_mode}}
                  </p>
                </div>
              </div>
            </div>
          </div>
<hr>
          <div class="row">
            <div class="col-md-6">
              <h5>{{detail.corporate_request.corpBranchName}}</h5>
              <address>
                {{detail.corporate_request.address}}<br>
                <b>GSTIN/UIN</b> : {{detail.corporate_request.gstNo}}<br>
                <b>PAN No.</b> : {{detail.corporate_request.panNo}}<br>
                <b>CIN</b> : {{detail.corporate_request.cinNo}}<br>
                <b>Contact</b> - {{detail.corporate_request.phoneNo}}
              </address>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <p><b>Supplier's Ref</b><br>
                        {{detail.invoice_no}}
                      </p>
                    </div>
                    <div class="col-12">
                      <p><b>Vendor Name</b><br>
                        {{detail.restaurant_request.restaurantName}}
                      </p>
                    </div>
                    <div class="col-12">
                      <p><b>Vendor FSS LIC NO</b><br>
                        {{detail.restaurant_request.licenseNO}}
                      </p>
                    </div>
                  </div>


                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <p><b>Buyers Order No</b><br>
                        {{detail.buyer_order_no}}
                      </p>
                    </div>
                    <div class="col-12">
                      <p><b>Date of Service</b><br>
                        {{detail.service_date}}
                      </p>
                    </div>
                    <div class="col-12">
                      <p><b>Destination</b><br>
                        {{detail.destination}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p><b>Terms of Delivery</b><br>
                    {{termOfDelivery}}
                  </p>
                </div>
              </div>
            </div>

          </div>
    <div class="row">
      <div class="col-md-12">
    <div class="table-responsive mb-0">
      <b-table
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :items="detail.items"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          fixed-header
          hover
          responsive="sm"
          sticky-header="500px"
          @filtered="onFiltered"
          head-variant="light"
      >
      </b-table>
    </div>
  </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-8">
            <p><b>Total Taxable Value</b>
            </p>
          </div>
          <div class="col-md-4">
            <p class="pull-right"><b>₹ {{detail.sub_total}}</b></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <p><b>CGST</b>
            </p>
          </div>
          <div class="col-md-4">
            <p class="pull-right"><b>₹ {{detail.cgst}}</b></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <p><b>SGST</b>
            </p>
          </div>
          <div class="col-md-4">
            <p class="pull-right"><b>₹ {{detail.sgst}}</b></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <p><b>Total</b>
            </p>
          </div>
          <div class="col-md-4">
            <p class="pull-right"><b>₹ {{detail.total_amount}}</b></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p>Amount Chargeable (In Words)<br>
              <b>INR. {{numToWord(detail.total_amount)}}</b>
            </p>
          </div>
        </div>
</div>
            </div>
          <hr>
          <div class="row">
           <div class="col-md-2" style="border: 1px solid #000; ">
             <p><b>SAC</b></p>
           </div>
            <div class="col-md-2" style="border: 1px solid #000; ">
              <p><b>Taxable Value</b></p>
            </div>
            <div class="col-md-6" style="border: 1px solid #000; ">
              <div class="row">
                <div class="col-md-6" style="border: 1px solid #000; ">
                  <p><b>Central Tax</b></p>
                  <div class="row">
                    <div class="col-md-6" style="border: 1px solid #000; ">
                      Rate
                    </div>
                    <div class="col-md-6" style="border: 1px solid #000; ">
                      Amount
                    </div>
                  </div>
                </div>
                <div class="col-md-6" style="border: 1px solid #000; ">
                  <p><b>State Tax</b></p>
                  <div class="row">
                    <div class="col-md-6" style="border: 1px solid #000; ">
                      Rate
                    </div>
                    <div class="col-md-6" style="border: 1px solid #000; ">
                      Amount
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2" style="border: 1px solid #000; ">
              <p><b>Total Tax Amount</b></p>
            </div>

          </div>
          <div class="row" v-for="(data) in detail.items" :key="data.id">
            <div class="col-md-2" style="border: 1px solid #000; ">
              <p><b>{{data.sac}}</b></p>
            </div>
            <div class="col-md-2" style="border: 1px solid #000; ">
              <p><b>₹ {{data.amount}}</b></p>
            </div>
            <div class="col-md-6" style="border: 1px solid #000; ">
              <div class="row">
                <div class="col-md-6" >
                  <div class="row">
                    <div class="col-md-6">
                      {{data.gst_percentage/2}} %
                    </div>
                    <div class="col-md-6">
                      ₹ {{(data.amount*data.gst_percentage/2)/100}}
                    </div>
                  </div>
                </div>
                <div class="col-md-6" >
                  <div class="row">
                    <div class="col-md-6" >
                      {{data.gst_percentage/2}} %
                    </div>
                    <div class="col-md-6" >
                      ₹ {{(data.amount*data.gst_percentage/2)/100}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2" style="border: 1px solid #000; ">
              <p><b> ₹ {{(data.amount*data.gst_percentage)/100}}</b></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <p><b>Tax Amount (In Words): {{numToWord(detail.total_tax)}}</b></p>
              <p style="text-decoration: underline">Declaration:</p>
              <p>Terms And Conditions:-</p>
              <ul>
                <li>
                  Please verify the correctness of all information and details of supplies.
                </li>
                <li>
                  The payment against this tax invoice is due after 7 days from the date of invoice. In case of delay, interest at 18% per annum will be applicable.
                </li>
                <li>
                  Disputes, of any, under this Tax invoice for supply and payment, shall be under the jurisdiction of courts in Mumbai, Maharashtra.
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <div class="row mt-5">
                <div class="col-md-6">
                  <p><b>Company's Bank Details</b></p>
                </div>
                <div class="col-md-6">
                  {{detail.ck_bank.name}}
                </div>
                <div class="col-md-6">
                  <p><b>Bank Name</b></p>
                </div>
                <div class="col-md-6">
                  {{detail.ck_bank.bank_name}}
                </div>
                <div class="col-md-6">
                  <p><b>Account Number</b></p>
                </div>
                <div class="col-md-6">
                  {{detail.ck_bank.account_no}}
                </div>
                <div class="col-md-6">
                  <p><b>Branch</b></p>
                </div>
                <div class="col-md-6">
                  {{detail.ck_bank.branch}}
                </div>
                <div class="col-md-6">
                  <p><b>IFSC CODE</b></p>
                </div>
                <div class="col-md-6">
                  {{detail.ck_bank.ifsc}}
                </div>
<div class="col-md-12 mt-3">
  <p style="text-decoration: underline"><b>For {{detail.ck_bank.name}}</b></p>
  <br>
  <br>
  <br>
  <br>
  <p>Authorised Signatory</p>
</div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
  </Layout>
</template>

<style scoped>

</style>